<template>
  <Navbar :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Start of contract-->
      <div class="form-group">
        <label for="start">Inicio de contrato</label>
        <v-date-picker
          id="start"
          name="start"
          v-model="document.start"
          color="red"
          is-expanded
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.start" class="error">{{ this.validationErrors.start }}</span>
      </div>
      <!--End of contract-->
      <div class="form-group">
        <label for="end">Fin de contrato</label>
        <v-date-picker
          id="end"
          name="end"
          v-model="document.end"
          color="red"
          is-expanded
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.end" class="error">{{ this.validationErrors.end }}</span>
      </div>
      <!--Currency-->
      <div class="form-group">
        <label for="currency">Moneda</label>
        <select v-model="document.currency" class="select" name="currency" id="currency">
          <option v-for="(c, index) in currencies" :value="c" :key="index">{{ c.name }}</option>
        </select>
        <span v-if="this.validationErrors.currency" class="error">{{ this.validationErrors.currency }}</span>
      </div>
      <!--Rent-->
      <div class="form-group">
        <label for="rent">Renta mensual</label>
        <input v-model.number="document.rent" class="input" type="number" id="rent" name="rent">
        <span v-if="this.validationErrors.rent" class="error">{{ this.validationErrors.rent }}</span>
      </div>
      <!--Warranty-->
      <div class="form-group">
        <label for="rent">Garantía</label>
        <input v-model.number="document.warranty" class="input" type="number" id="warranty" name="warranty">
        <span v-if="this.validationErrors.warranty" class="error">{{ this.validationErrors.warranty }}</span>
      </div>
      <!--Attach PDF-->
      <div v-if="!attach" class="form-group">
        <input @change="updateDoc" class="file" ref="uploadVoucher" type="file" accept="application/pdf">
        <button class="btn btn--outline btn--w100" @click="$refs.uploadVoucher.click()">Adjuntar contrato</button>
      </div>
      <div v-else>
        <span>{{ attach.name }}</span>
      </div>
      <!--End-->
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { db, FieldValue, storage } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'NewContract',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    client: {
      type: Object,
      default: () => {
        return { currency: { code: '' } }
      }
    }
  },
  components: {
    Navbar,
    Shadow
  },
  data () {
    return {
      document: {},
      schema: yup.object().shape({
        start: yup.date().required().label('Inicio'),
        end: yup.date().required().label('Fin'),
        currency: yup.object().required().label('Moneda'),
        rent: yup.number().required().positive().label('Renta'),
        warranty: yup.number().required().label('Garantía')
      }),
      validationErrors: {},
      progress: false,
      attach: null,
      currencies: [
        {
          name: 'Soles',
          code: 'PEN'
        },
        {
          name: 'Dólares',
          code: 'USD'
        }
      ]
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {}
        this.validationErrors = {}
        this.attach = null
      }
    }
  },
  methods: {
    /**
     * Save in firestore
     * @returns {Promise<void>}
     */
    async save () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        const obj = {
          ...this.document,
          createdAt: FieldValue.serverTimestamp(),
          uid: '',
          state: true,
          client: {
            id: this.client.id,
            name: this.client.name,
            document: this.client.document,
            day: this.client.day,
            email: this.client.email,
            local: this.client.local
          },
          quantityBills: 0,
          quantityTaxes: 0,
          expired: false
        }
        if (this.attach) {
          obj.pdf = await this.submitFile(this.attach)
        }
        const doc = await db.collection('clients').doc(this.client.id).collection('contracts').add({
          ...obj
        })
        obj.id = doc.id
        this.$emit('add', obj)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al guardar contrato')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    },
    /**
     * Update doc
     * */
    updateDoc (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.attach = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Save doc in firebase storage
     * @param doc
     * @returns {Promise<unknown>}
     */
    submitFile (doc) {
      return new Promise((resolve, reject) => {
        const docName = Math.random().toString(36).substr(1, 20)
        const fileExtension = '.' + doc.name.split('.').pop()
        const docNameFinal = docName + fileExtension

        const storageRef = storage.ref(`contracts/${docNameFinal}`)
        storageRef.put(doc)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              resolve(url)
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
