<template>
  <div>
    <div class="title">
      <div class="title__right">
        <!--Desktop back-->
        <span class="back desktop" @click="$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
        </span>
        <!--Mobile back-->
        <span @click="$router.go(-1)" class="back mobile">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
          Regresar
        </span>
        <h1>Listado de impuestos ({{ estate.description }})</h1>
      </div>
      <div class="toolbar">
        <button @click="showGenerateSidebar= true" class="btn btn--outline">Generar impuestos</button>
        <button @click="showNewSidebar= true" class="btn">Agregar impuesto</button>
      </div>
    </div>
    <div class="bills">
      <div class="table">
        <div class="table__header table__header--five">
          <div><span>TIPO</span></div>
          <div><span>TOTAL</span></div>
          <div><span>FECHA DE VENCIMIENTO</span></div>
          <div><span>ESTADO</span></div>
          <div><span>ACCIONES</span></div>
        </div>
        <div class="table__content">
          <div v-for="(o, index) in list" :key="index" class="row row--five" :class="{'selected': selected.id === o.id}"
               @click="selected = o">
            <div class="row__item">
              <span class="name">TIPO</span>
              <span>{{ o.type }}</span>
            </div>
            <div class="row__item">
              <span class="name">TOTAL</span>
              <span>{{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: `${o.currency.code}`
                  }).format(o.total)
                }}</span>
            </div>
            <div class="row__item">
              <span class="name">FECHA DE VENCIMIENTO</span>
              <span>{{ moment(o.deadline).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
            </div>
            <div class="row__item badge" :class="{'badge--error':  !o.paid}">
              <span v-if="!o.paid">Pendiente</span>
              <span v-else>Pagada</span>
            </div>
            <!--Desktop Actions-->
            <div class="row__item desktop">
              <span v-if="!o.paid" title="Editar" @click="showUpdateSidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-edit"><path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
              </span>
              <span title="Registrar pago" @click="showPaySidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path
                  d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path
                  d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              </span>
            </div>
            <!--Mobile Actions-->
            <div class="row__item mobile">
              <button class="btn btn---block mb-8" @click="showPaySidebar = true, sidebarData= o">Registrar pago
              </button>
              <button v-if="!o.paid" class="btn btn---block btn--outline"
                      @click="showUpdateSidebar = true, sidebarData= o">
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <new-tax :estate="estate" @close="showNewSidebar = false" @add="addSidebar" :class="{'show' : showNewSidebar}"
             title="Nuevo impuesto" :show-navbar="showNewSidebar"></new-tax>
    <update-tax :data="sidebarData" @close="showUpdateSidebar = false" @update="updateSidebar"
                :class="{'show' : showUpdateSidebar}"
                title="Editar impuesto" :show-navbar="showUpdateSidebar"></update-tax>
    <generate-tax :estate="estate" @close="showGenerateSidebar = false" @add="addSidebar"
                  :class="{'show' : showGenerateSidebar}"
                  title="Generar impuestos" :show-navbar="showGenerateSidebar"></generate-tax>
    <pay :data="sidebarData" @close="showPaySidebar = false" @update="updateSidebar"
         :class="{'show' : showPaySidebar}"
         :show-navbar="showPaySidebar"></pay>
  </div>
</template>

<script>
import { db } from '@/firebase/firebaseConfig'
import NewTax from './NewTax'
import UpdateTax from './UpdateTax'
import GenerateTax from './Generate'
import Pay from './Pay'
import moment from 'moment'

export default {
  name: 'Taxes',
  components: {
    NewTax,
    UpdateTax,
    GenerateTax,
    Pay
  },
  data () {
    return {
      list: [],
      estate: {
        currency: {}
      },
      moment: moment,
      showNewSidebar: false,
      showUpdateSidebar: false,
      showGenerateSidebar: false,
      showPaySidebar: false,
      sidebarData: {},
      selected: {}
    }
  },
  async created () {
    try {
      this.$store.dispatch('showProgress')
      await this.getState()
      await this.getTaxes()
    } catch (e) {
      console.log(e)
      this.$toast.error('Error al cargar datos')
    } finally {
      this.$store.dispatch('hideProgress')
    }
  },
  methods: {
    addSidebar (obj) {
      this.list.push(obj)
    },
    updateSidebar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get client
     * @returns {Promise<void>}
     */
    async getState () {
      const doc = await db.collection('estates').doc(this.$route.params.id).get()
      if (doc.exists) {
        this.estate = {
          id: doc.id,
          ...doc.data()
        }
      }
    },
    /**
     * Get taxes
     * @returns {Promise<void>}
     */
    async getTaxes () {
      const querySnap = await db.collection('estates').doc(this.estate.id).collection('propertyTaxes').orderBy('deadline', 'asc').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.deadline = doc.data().deadline.toDate()
        obj.paymentDate = doc.data().paymentDate ? doc.data().paymentDate.toDate() : null
        this.list.push(obj)
      })
    }
  }
}
</script>

<style scoped>

</style>
