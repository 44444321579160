<template class="test">
  <div class="login">
    <div class="login__wrap">
      <svg xmlns="http://www.w3.org/2000/svg" width="436.722" height="196.02" viewBox="0 0 436.722 196.02">
        <g id="Grupo_3" data-name="Grupo 3" transform="translate(-181.639 -301.99)">
          <g id="Grupo_1" data-name="Grupo 1">
            <path id="Trazado_1" data-name="Trazado 1"
                  d="M200.442,323.826a18.578,18.578,0,1,1,18.579,18.58,18.578,18.578,0,0,1-18.579-18.58"
                  fill="#b51a3e"/>
            <path id="Trazado_2" data-name="Trazado 2"
                  d="M242.222,363.76c-.627-4.154-3.625-14.423-17.971-14.423h-1.5c-2.2.039-4.938.278-5,.284l-30.847,2.936a4.942,4.942,0,0,0-1.969.822c-.08.065-.133.139-.207.207a4.5,4.5,0,0,0-1.3,2.031,9.119,9.119,0,0,0-.417,1.358c0,.017-.012.023-.012.044,0,.006,0,.006,0,.015s-.017.088-.017.088l-1.34,16.475-.009.568a3.312,3.312,0,0,0,.991,2.365,3.357,3.357,0,0,0,2.365,1.017l17.731-1.836a4.185,4.185,0,0,1,4.586,3.743l6.091,61c.751,4.6,4.21,14.987,20.283,15.162,14.023.153,16.365-8.152,16.427-13.5Z"
                  fill="#b51a3e"/>
          </g>
          <path id="Trazado_3" data-name="Trazado 3"
                d="M336.106,377.23q-4.62-3.738-12.321-3.74a33.57,33.57,0,0,0-9.679,1.32,44.848,44.848,0,0,0-8.14,3.3v77.88a43.541,43.541,0,0,1-6.16,1.21,64.762,64.762,0,0,1-8.8.55q-9.9,0-14.3-3.19t-4.4-12.431V374.15a16,16,0,0,1,2.42-9.24,26.743,26.743,0,0,1,6.82-6.6q7.038-5.058,18.15-8.14a91.712,91.712,0,0,1,24.531-3.08q23.976,0,37.068,10.56t13.092,30.14v68.2a53.11,53.11,0,0,1-6.381,1.21,64.767,64.767,0,0,1-8.8.55q-9.9,0-14.189-3.19t-4.289-12.431v-53.46Q340.727,380.972,336.106,377.23Z"
                fill="#b51a3e"/>
          <path id="Trazado_4" data-name="Trazado 4"
                d="M501.545,496.03a52.347,52.347,0,0,1-6.271,1.32,54.315,54.315,0,0,1-8.689.66q-9.9,0-14.3-3.3t-4.4-12.54V456.43a55.533,55.533,0,0,1-8.691,2.2,59.83,59.83,0,0,1-10.449.88,64.675,64.675,0,0,1-21.78-3.519,45.966,45.966,0,0,1-28.49-27.941,67.575,67.575,0,0,1-4.069-24.42q0-14.074,4.289-24.639a47.186,47.186,0,0,1,12.1-17.6,52.163,52.163,0,0,1,18.7-10.67,76.575,76.575,0,0,1,24.309-3.63,82.2,82.2,0,0,1,22.55,2.97q10.447,2.97,16.17,6.71a21.929,21.929,0,0,1,6.71,6.49,17.154,17.154,0,0,1,2.311,9.35Zm-47.96-63.14a28.056,28.056,0,0,0,14.519-3.74V376.79a39.927,39.927,0,0,0-5.719-2.2,26.561,26.561,0,0,0-7.92-1.1q-11.88,0-18.92,7.37t-7.04,22.769q0,15.182,6.6,22.221T453.585,432.89Z"
                fill="#b51a3e"/>
          <path id="Trazado_5" data-name="Trazado 5"
                d="M525.744,320.03a17.57,17.57,0,0,1,5.17-12.76q5.166-5.28,13.529-5.28t13.53,5.28a17.582,17.582,0,0,1,5.17,12.76,17.841,17.841,0,0,1-5.17,12.98q-5.171,5.28-13.53,5.28t-13.529-5.28A17.829,17.829,0,0,1,525.744,320.03Zm35.42,135.96A43.571,43.571,0,0,1,555,457.2a64.774,64.774,0,0,1-8.8.55q-9.9,0-14.191-3.19t-4.29-12.431V351.71a52.8,52.8,0,0,1,6.161-1.32,53.732,53.732,0,0,1,8.8-.66q9.9,0,14.191,3.3t4.29,12.54Z"
                fill="#b51a3e"/>
          <path id="Trazado_6" data-name="Trazado 6"
                d="M579.2,439.93a20.23,20.23,0,0,1,5.17-14.08q5.166-5.718,14.41-5.721,9.018,0,14.3,5.721a21.412,21.412,0,0,1,0,28.159q-5.28,5.719-14.3,5.721-9.24,0-14.41-5.721A20.214,20.214,0,0,1,579.2,439.93Z"
                fill="#b51a3e"/>
        </g>
      </svg>
      <div class="login__form">
        <div class="form-group">
          <label for="user">Usuario</label>
          <input v-model="user.email" class="input" type="email" name="user" id="user">
          <span v-if="this.validationErrors.email" class="error">{{ this.validationErrors.email }}</span>
        </div>
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input @keydown.enter="login" v-model="user.password" class="input" type="password" name="user" id="password">
          <span v-if="this.validationErrors.password" class="error">{{ this.validationErrors.password }}</span>
        </div>
        <button :class="{'is-loading': progress}" type="button" @click="login" class="btn btn---block">
          <span v-if="!progress">Ingresar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero'
  },
  string: {
    email: 'Debe ser  un email'
  }
})

export default {
  name: 'Login',
  data () {
    return {
      progress: false,
      user: {
        email: '',
        password: ''
      },
      schema: yup.object().shape({
        email: yup.string().email().required().label('Email'),
        password: yup.string().required().label('Contraseña')
      }),
      validationErrors: {}
    }
  },
  methods: {
    async login () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.user }, { abortEarly: false })
        await auth.signInWithEmailAndPassword(this.user.email, this.user.password)
        this.$store.dispatch('hideLogin')
        await this.$router.push('/')
      } catch (err) {
        this.$toast.error('Error al ingresar')
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        }
        if (err.code === 'auth/invalid-email') {
          this.validationErrors.email = 'Email inválido'
        } else if (err.code === 'auth/user-disabled') {
          this.validationErrors.email = 'Usuario eliminado'
        } else if (err.code === 'auth/user-not-found') {
          this.validationErrors.email = 'Usuario no existe'
        } else if (err.code === 'auth/wrong-password') {
          this.validationErrors.password = 'Contraseña incorrecta'
        }
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
