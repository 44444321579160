<template>
  <div class="navbar">
    <div class="navbar__header">
      <h2>{{ title }}</h2>
      <slot name="close">
        <div @click="$emit('close')" class="close">
          Cerrar
        </div>
      </slot>
    </div>
    <div class="navbar__content">
      <slot name="content"></slot>
    </div>
    <div class="navbar__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    title: {
      type: String
    }
  }
}
</script>
