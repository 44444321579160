<template>
  <Navbar class="navbar--800px" :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Local-->
      <div class="form-group">
        <label for="local">Expositor</label>
        <select v-model="document.client" class="select" name="local" id="local">
          <option v-for="(c, index) in clients" :value="c" :key="index">{{ c.alias }}</option>
        </select>
        <span v-if="this.validationErrors.local" class="error">{{ this.validationErrors.local }}</span>
      </div>
      <!--Currency-->
      <div class="form-group">
        <label for="currency">Moneda</label>
        <select v-model="document.currency" class="select" name="currency" id="currency">
          <option v-for="(c, index) in currencies" :value="c" :key="index">{{ c.name }}</option>
        </select>
        <span v-if="this.validationErrors.currency" class="error">{{ this.validationErrors.currency }}</span>
      </div>
      <!--Date of Issue-->
      <div class="form-group">
        <label for="dateOfIssue">Fecha de emisión</label>
        <v-date-picker
          id="dateOfIssue"
          name="dateOfIssue"
          v-model="document.dateOfIssue"
          color="red"
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.dateOfIssue" class="error">{{ this.validationErrors.dateOfIssue }}</span>
      </div>
      <hr class="mt-4 mb-16">
      <!--Pre form-->
      <div class="mb-16">
        <div class="grid col-3 gap-2">
          <div class="form-group">
            <label for="productName">Nombre de producto*</label>
            <input class="input" v-model="product.name" type="text">
          </div>
          <div class="form-group">
            <label for="quantity">Cantidad*</label>
            <input class="input" v-model.number="product.quantity" type="number">
          </div>
          <div class="form-group">
            <label for="price">Precio*</label>
            <input class="input" type="number" v-model.number="product.price">
          </div>
        </div>
        <button :disabled="!document.currency.code" @click="addProduct" class="btn btn---block btn--outline"
                style="width: 100%">Agregar
        </button>
      </div>
      <!--Added products-->
      <div>
        <div class="table">
          <div class="table__header table__header--three">
            <span>Nombre</span>
            <span>Cantidad</span>
            <span>Precio</span>
          </div>
          <div class="table__content">
            <template v-for="(p, index) in products" :key="index">
              <div class="row row--three">
                <span>{{ p.name }}</span>
                <span>{{ p.quantity }}</span>
                <span>
                  {{
                    Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: `${document.currency.code}`
                    }).format(p.price)
                  }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!--Total-->
      <div class="grid col-3 mt-16">
        <span></span>
        <span></span>
        <span style="text-align: right">
          <hr class="mt-4 mb-16">
          Total :  {{
            Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: `${document.currency.code}`
            }).format(total)
          }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { auth, db, FieldValue } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'NewSale',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    clients: {
      type: Array,
      required: true
    }
  },
  components: {
    Navbar,
    Shadow
  },
  computed: {
    total () {
      // eslint-disable-next-line no-return-assign
      return this.products.reduce((a, c) => { return a + (c.price * c.quantity) }, 0)
    }
  },
  data () {
    return {
      document: {
        currency: {
          code: 'PEN'
        }
      },
      schema: yup.object().shape({
        dateOfIssue: yup.date().required().label('Emisión'),
        currency: yup.object().required().label('Moneda'),
        client: yup.object().required().label('Cliente')
      }),
      validationErrors: {},
      progress: false,
      currencies: [
        {
          name: 'Soles',
          code: 'PEN'
        },
        {
          name: 'Dólares',
          code: 'USD'
        }
      ],
      products: [],
      product: {
        quantity: 0,
        price: 0
      }
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {
          currency: {
            code: 'PEN'
          }
        }
        this.products = []
        this.product = {
          quantity: 0,
          price: 0
        }
        this.validationErrors = {}
      }
    }
  },
  methods: {
    /**
     * Save in firestore database
     * @returns {Promise<void>}
     */
    async save () {
      try {
        if (this.total > 0) {
          this.progress = true
          this.validationErrors = {}
          await this.schema.validate({ ...this.document }, { abortEarly: false })
          const obj = {
            ...this.document,
            createdAt: FieldValue.serverTimestamp(),
            uid: auth.currentUser.uid,
            state: true,
            total: parseFloat(this.total.toFixed(2)),
            products: this.products
          }
          const doc = await db.collection('sales').add({
            ...obj
          })
          obj.id = doc.id
          this.$emit('add', obj)
          this.$emit('close')
        } else {
          this.$toast.error('Debes agregar productos')
        }
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al guardar venta')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    },
    /**
     * Add product
     */
    addProduct () {
      if (this.product.price !== 0 && this.product.quantity !== 0) {
        this.products.push({
          ...this.product
        })
        this.product = {
          quantity: 0,
          price: 0
        }
      }
    }

  }
}
</script>
