<template>
  <Navbar title="Generar letras" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Highlight-->
      <div class="bills__high">
        <div class="item">
          <span class="item__label">Renta mensual</span>
          <span class="item__rent">{{
              Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: client.currency.code ? `${client.currency.code}` : 'PEN'
              }).format(client.rent)
            }}</span>
        </div>
        <div class="item">
          <span class="item__label">Día de pago</span>
          <span class="item__rent">{{ client.day }}</span>
        </div>
      </div>
      <!--End-->
      <!--Quantity-->
      <div class="form-group">
        <label for="rent">Cantidad de letras</label>
        <input v-model.number="document.quantity" class="input" type="number" id="rent"
               name="rent">
        <span v-if="this.validationErrors.quantity" class="error">{{ this.validationErrors.quantity }}</span>
      </div>
      <!--Include-->
      <div class="form-group">
        <label for="include">Incluir este mes</label>
        <select v-model="include" class="select" name="include" id="include">
          <option :value="true">Si</option>
          <option :value="false">No</option>
        </select>
        <span v-if="this.validationErrors.include" class="error">{{ this.validationErrors.include }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="generate" class="btn">
        <span v-if="!progress">Generar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'

import * as yup from 'yup'
import { db, FieldValue } from '@/firebase/firebaseConfig'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'Generate',
  components: {
    Navbar,
    Shadow
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    client: {
      type: Object,
      default: () => {
        return { currency: { code: '' } }
      }
    }
  },
  data () {
    return {
      document: {
        quantity: 1
      },
      progress: false,
      schema: yup.object().shape({
        quantity: yup.number().required().positive().label('Cantidad')
      }),
      include: true,
      validationErrors: {}
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {
          quantity: 1
        }
      }
    }
  },
  methods: {
    async generate () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        let initMonth = 0
        if (this.include) {
          initMonth = new Date().getMonth()
        } else {
          initMonth = new Date().getMonth() + 1
        }
        const bills = []
        let fullYear = new Date().getFullYear()
        for (let i = 0; i < this.document.quantity; i++) {
          let billDate = new Date()
          if (initMonth === 12) {
            initMonth = 0
            fullYear++
          }
          billDate = new Date(billDate.setDate(this.client.day))
          billDate = new Date(billDate.setFullYear(fullYear))
          billDate = new Date(billDate.setMonth(initMonth))
          bills.push(billDate)
          initMonth++
        }
        // Save
        const batch = db.batch()
        bills.forEach((b) => {
          const obj = {
            total: this.client.rent,
            createdAt: FieldValue.serverTimestamp(),
            uid: '',
            state: true,
            paid: false,
            client: {
              id: this.client.id,
              name: this.client.name,
              document: this.client.document,
              currency: this.client.currency,
              rent: this.client.rent,
              day: this.client.day,
              local: this.client.local,
              currentContract: this.client.currentContract
            },
            deadline: b
          }
          const docRef = db.collection('clients').doc(this.client.id).collection('bills').doc()
          obj.id = docRef.id
          batch.set(docRef, {
            ...obj
          })
          this.$emit('add', obj)
        })
        const response = await batch.commit()
        console.log(response)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al generar letras')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
