<template>
  <Navbar title="Generar letras" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Type-->
      <div class="form-group">
        <label for="typeDocument">Tipo de impuesto</label>
        <select v-model="document.type" class="select" name="typeDocument" id="typeDocument">
          <option value="Arbitrios">Arbitrios</option>
          <option value="Predial">Predial</option>
        </select>
        <span v-if="this.validationErrors.type" class="error">{{ this.validationErrors.type }}</span>
      </div>
      <!--Total-->
      <div class="form-group">
        <label for="rent">Total</label>
        <input v-model.number="document.total" class="input" type="number" id="total"
               name="total">
        <span v-if="this.validationErrors.total" class="error">{{ this.validationErrors.total }}</span>
      </div>
      <!--Quantity-->
      <div class="form-group">
        <label for="rent">Cantidad de repeticiones</label>
        <input v-model.number="document.quantity" class="input" type="number" id="rent"
               name="rent">
        <span v-if="this.validationErrors.quantity" class="error">{{ this.validationErrors.quantity }}</span>
      </div>
      <!--Day-->
      <div class="form-group">
        <label for="rent">Día de pago</label>
        <input v-model.number="document.day" class="input" type="number" id="day"
               name="day">
        <span v-if="this.validationErrors.day" class="error">{{ this.validationErrors.day }}</span>
      </div>
      <!--Include-->
      <div class="form-group">
        <label for="include">Incluir este mes</label>
        <select v-model="include" class="select" name="include" id="include">
          <option :value="true">Si</option>
          <option :value="false">No</option>
        </select>
        <span v-if="this.validationErrors.include" class="error">{{ this.validationErrors.include }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="generate" class="btn">
        <span v-if="!progress">Generar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'

import * as yup from 'yup'
import { db, FieldValue } from '@/firebase/firebaseConfig'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'Generate',
  components: {
    Navbar,
    Shadow
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    estate: {
      type: Object,
      default: () => {
        return { currency: { code: '' } }
      }
    }
  },
  data () {
    return {
      document: {
        quantity: 1
      },
      progress: false,
      schema: yup.object().shape({
        quantity: yup.number().required().positive().label('Cantidad'),
        day: yup.number().required().positive().label('Día'),
        total: yup.number().required().positive().label('Cantidad'),
        type: yup.string().required().label('Tipo')
      }),
      include: true,
      validationErrors: {}
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {
          quantity: 1,
          percentage: 5
        }
        this.validationErrors = {}
      }
    }
  },
  methods: {
    async generate () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        let initMonth = 0
        if (this.include) {
          initMonth = new Date().getMonth()
        } else {
          initMonth = new Date().getMonth() + 1
        }
        const bills = []
        let fullYear = new Date().getFullYear()
        for (let i = 0; i < this.document.quantity; i++) {
          let billDate = new Date()
          if (initMonth === 12) {
            initMonth = 0
            fullYear++
          }
          billDate = new Date(billDate.setDate(this.document.day))
          billDate = new Date(billDate.setFullYear(fullYear))
          billDate = new Date(billDate.setMonth(initMonth))
          bills.push(billDate)
          initMonth++
        }
        // Save
        const batch = db.batch()
        bills.forEach((b) => {
          const obj = {
            total: this.document.total,
            createdAt: FieldValue.serverTimestamp(),
            uid: '',
            state: true,
            paid: false,
            estate: {
              id: this.estate.id,
              description: this.estate.description
            },
            currency: {
              code: 'PEN'
            },
            deadline: b,
            type: this.document.type
          }
          const docRef = db.collection('estates').doc(this.estate.id).collection('propertyTaxes').doc()
          obj.id = docRef.id
          batch.set(docRef, {
            ...obj
          })
          this.$emit('add', obj)
        })
        await batch.commit()
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al generar impuestos')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
