<template>
  <Navbar title="Enviar notificación" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <div class="whatsApp">
        <span>Confirma número</span>
        <span>{{ document.phone }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :class="{'is-loading': progress}" @click="$emit('send', document), $emit('close')" class="btn">
        <span v-if="!progress">Enviar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'

import _ from 'lodash'

export default {
  name: 'SendNotification',
  components: {
    Navbar,
    Shadow
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = _.clone(this.data)
        this.validationErrors = {}
      }
    }
  },
  data () {
    return {
      document: {},
      progress: false,
      include: true,
      validationErrors: {}
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
