<template>
  <div>
    <div class="title">
      <div class="title__right">
        <!--Desktop back-->
        <span title="Regresar" class="back desktop" @click="$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
        </span>
        <!--Mobile back-->
        <span @click="$router.go(-1)" class="back mobile">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
          Regresar
        </span>
        <h1>Listado de contratos ({{ client.name }})</h1>
      </div>
      <div class="toolbar">
        <button @click="showNewSidebar= true" class="btn">Agregar contrato</button>
      </div>
    </div>
    <div class="bills">
      <div class="table">
        <div class="table__header table__header--six">
          <div><span>FECHA DE INICIO</span></div>
          <div><span>FECHA DE FIN</span></div>
          <div><span>TOTAL</span></div>
          <div><span>GARANTÍA</span></div>
          <div><span>ESTADO</span></div>
          <div><span>ACCIONES</span></div>
        </div>
        <div class="table__content">
          <template v-if="list.length>0">
            <div v-for="(o, index) in list" :key="index" class="row row--six"
                 :class="{'selected': selected.id === o.id}" @click="selected = o">
              <div class="row__item">
                <span class="name">FECHA DE INICIO</span>
                <span>{{ moment(o.start).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
              </div>
              <div class="row__item">
                <span class="name">FECHA DE FIN</span>
                <span>{{ moment(o.end).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
              </div>
              <div class="row__item">
                <span class="name">TOTAL</span>
                <span>{{
                    Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: `${o.currency.code}`
                    }).format(o.rent)
                  }}</span>
              </div>
              <div class="row__item">
                <span class="name">GARANTÍA</span>
                <span>{{
                    Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: `${o.currency.code}`
                    }).format(o.warranty)
                  }}</span>
              </div>
              <div class="row__item badge" :class="{'badge--error':  o.expired}">
                <span v-if="!o.expired">Activo</span>
                <span v-else>Vencido</span>
              </div>
              <!--Desktop Actions-->
              <div class="row__item desktop">
              <span v-if="!o.expired" title="Editar" @click="showUpdateSidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-edit"><path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
              </span>
                <span v-if="o.pdf" title="Ver PDF" @click="openPDF(o.pdf)">
                   <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path
                     d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72"
                     fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                     stroke-width="32"/></svg>
                </span>
              </div>
              <!--Mobile Actions-->
              <div class="row__item mobile">
                <button class="btn btn---block mb-8"
                        @click="showUpdateSidebar = true, sidebarData= o">
                  Editar
                </button>
                <button v-if="o.pdf" class="btn btn---block btn--outline"
                        @click="openPDF(o.pdf)">
                  Ver PDF
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="empty">
              <span>Sin datos para mostrar</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <new-tax :client="client" @close="showNewSidebar = false" @add="addSidebar" :class="{'show' : showNewSidebar}"
             title="Nuevo contrato" :show-navbar="showNewSidebar"></new-tax>
    <update-tax :data="sidebarData" @close="showUpdateSidebar = false" @update="updateSidebar"
                :class="{'show' : showUpdateSidebar}"
                title="Editar contrato" :show-navbar="showUpdateSidebar"></update-tax>
  </div>
</template>

<script>
import { db } from '@/firebase/firebaseConfig'
import NewTax from './NewContract'
import UpdateTax from './UpdateContract'
import moment from 'moment'

export default {
  name: 'Contracts',
  components: {
    NewTax,
    UpdateTax
  },
  data () {
    return {
      list: [],
      client: {
        currency: {}
      },
      moment: moment,
      showNewSidebar: false,
      showUpdateSidebar: false,
      sidebarData: {},
      selected: {}
    }
  },
  async created () {
    try {
      this.$store.dispatch('showProgress')
      await this.getClient()
      await this.getContracts()
    } catch (e) {
      console.log(e)
      this.$toast.error('Error al cargar contratos')
    } finally {
      this.$store.dispatch('hideProgress')
    }
  },
  methods: {
    addSidebar (obj) {
      this.list.unshift(obj)
      this.list.forEach((o) => {
        if (obj.id !== o.id) {
          o.expired = true
        }
      })
    },
    updateSidebar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get client
     * @returns {Promise<void>}
     */
    async getClient () {
      const doc = await db.collection('clients').doc(this.$route.params.id).get()
      if (doc.exists) {
        this.client = {
          id: doc.id,
          ...doc.data()
        }
      }
    },
    /**
     * Get taxes
     * @returns {Promise<void>}
     */
    async getContracts () {
      const querySnap = await db.collection('clients').doc(this.client.id).collection('contracts').orderBy('start', 'desc').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.start = doc.data().start.toDate()
        obj.end = doc.data().end ? doc.data().end.toDate() : null
        this.list.push(obj)
      })
    },
    /**
     * Open PDF in web
     */
    openPDF (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
