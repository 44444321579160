<template>
  <Navbar :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Start of contract-->
      <div class="form-group">
        <label for="start">Inicio de contrato</label>
        <v-date-picker
          :disabled="document.quantityBills!==0"
          ref="start"
          id="start"
          name="start"
          v-model="document.start"
          color="red"
          is-expanded
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.start" class="error">{{ this.validationErrors.start }}</span>
      </div>
      <!--End of contract-->
      <div class="form-group">
        <label for="end">Fin de contrato</label>
        <v-date-picker
          :disabled="document.quantityBills!==0"
          ref="end"
          id="end"
          name="end"
          v-model="document.end"
          color="red"
          is-expanded
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.end" class="error">{{ this.validationErrors.end }}</span>
      </div>
      <!--Currency-->
      <div class="form-group">
        <label for="currency">Moneda</label>
        <select v-model="document.currency" class="select" name="currency"
                id="currency">
          <option v-for="(c, index) in currencies" :value="c" :key="index">{{ c.name }}</option>
        </select>
        <span v-if="this.validationErrors.currency" class="error">{{ this.validationErrors.currency }}</span>
      </div>
      <!--Rent-->
      <div class="form-group">
        <label for="rent">Renta mensual</label>
        <input v-model.number="document.rent" class="input" type="number"
               id="rent" name="rent">
        <span v-if="this.validationErrors.rent" class="error">{{ this.validationErrors.rent }}</span>
      </div>
      <!--Warranty-->
      <div class="form-group">
        <label for="rent">Garantía</label>
        <input :disabled="true" v-model.number="document.warranty" class="input" type="number"
               id="warranty" name="warranty">
        <span v-if="this.validationErrors.warranty" class="error">{{ this.validationErrors.warranty }}</span>
      </div>
      <!--Attach PDF-->
      <template v-if="!document.pdf">
        <div v-if="!attach" class="form-group">
          <input @change="updateDoc" class="file" ref="uploadVoucher" type="file" accept="application/pdf">
          <button class="btn btn--outline btn--w100" @click="$refs.uploadVoucher.click()">Adjuntar contrato</button>
        </div>
        <div v-else>
          <span>{{ attach.name }}</span>
        </div>
      </template>
      <!--End-->
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { db, FieldValue, storage } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

const _ = require('lodash')

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'UpdateContract',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Navbar,
    Shadow
  },
  data () {
    return {
      document: {
        client: {
          currency: {
            code: 'PEN'
          }
        }
      },
      schema: yup.object().shape({
        start: yup.date().required().label('Inicio'),
        end: yup.date().required().label('Fin'),
        currency: yup.object().required().label('Moneda'),
        rent: yup.number().required().positive().label('Renta'),
        warranty: yup.number().required().label('Garantía')
      }),
      validationErrors: {},
      progress: false,
      currencies: [
        {
          name: 'Soles',
          code: 'PEN'
        },
        {
          name: 'Dólares',
          code: 'USD'
        }
      ],
      attach: null
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = _.clone(this.data)
        this.validationErrors = {}
        this.attach = null
        // Move calendar
        const start = this.$refs.start
        start.move(this.document.start)
        const end = this.$refs.end
        end.move(this.document.end)
      }
    }
  },
  methods: {
    /**
     * Save in firestore
     * @returns {Promise<void>}
     */
    async save () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        const obj = {
          ...this.document,
          updatedAt: FieldValue.serverTimestamp(),
          uid: ''
        }
        if (this.attach) {
          obj.pdf = await this.submitFile(this.attach)
        }
        await db.collection('clients').doc(this.document.client.id).collection('contracts').doc(this.document.id).update({
          ...obj
        })
        this.$emit('update', obj)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al editar contrato')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    },
    /**
     * Update doc
     * */
    updateDoc (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.attach = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Save doc in firebase storage
     * @param doc
     * @returns {Promise<unknown>}
     */
    submitFile (doc) {
      return new Promise((resolve, reject) => {
        const docName = Math.random().toString(36).substr(1, 20)
        const fileExtension = '.' + doc.name.split('.').pop()
        const docNameFinal = docName + fileExtension

        const storageRef = storage.ref(`contracts/${docNameFinal}`)
        storageRef.put(doc)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              resolve(url)
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
