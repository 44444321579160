<template>
  <Navbar :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Local-->
      <div class="form-group">
        <label for="local">Propiedad</label>
        <select v-model="document.local" class="select" name="local" id="local">
          <option v-for="(e, index) in estates" :value="e" :key="index">{{ e.description }}</option>
        </select>
        <span v-if="this.validationErrors.local" class="error">{{ this.validationErrors.local }}</span>
      </div>
      <!--Type-->
      <div class="form-group">
        <label for="type">Tipo de gasto</label>
        <select v-model="document.type" class="select" name="type" id="type">
          <option v-for="(o, index) in types" :key="index" :value="o">{{ o }}</option>
        </select>
        <span v-if="this.validationErrors.type" class="error">{{ this.validationErrors.type }}</span>
      </div>
      <!--Observation-->
      <div class="form-group">
        <label for="observation">Observación</label>
        <input v-model="document.observation" class="input" type="text" id="observation" name="observation">
        <span v-if="this.validationErrors.observation" class="error">{{ this.validationErrors.name }}</span>
      </div>
      <!--Currency-->
      <div class="form-group">
        <label for="currency">Moneda</label>
        <select v-model="document.currency" class="select" name="currency" id="currency">
          <option v-for="(c, index) in currencies" :value="c" :key="index">{{ c.name }}</option>
        </select>
        <span v-if="this.validationErrors.currency" class="error">{{ this.validationErrors.currency }}</span>
      </div>
      <!--Total-->
      <div class="form-group">
        <label for="rent">Total</label>
        <input v-model.number="document.total" class="input" type="number" id="rent"
               name="rent">
        <span v-if="this.validationErrors.total" class="error">{{ this.validationErrors.total }}</span>
      </div>
      <!--Deadline-->
      <div class="form-group">
        <label for="deadline">Fecha de vencimiento</label>
        <v-date-picker
          id="deadline"
          name="deadline"
          v-model="document.deadline"
          color="red"
          is-expanded
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.deadline" class="error">{{ this.validationErrors.deadline }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { auth, db, FieldValue } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'NewExpense',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    estates: {
      type: Array,
      required: true
    }
  },
  components: {
    Navbar,
    Shadow
  },
  data () {
    return {
      document: {},
      schema: yup.object().shape({
        deadline: yup.date().required().label('Vencimiento'),
        total: yup.number().required().positive().label('Total'),
        type: yup.string().required().label('Tipo'),
        currency: yup.object().required().label('Moneda'),
        local: yup.object().required().label('Local')
      }),
      validationErrors: {},
      progress: false,
      types: [
        'Planilla',
        'Agua',
        'Luz',
        'Internet',
        'Misceláneos'
      ],
      currencies: [
        {
          name: 'Soles',
          code: 'PEN'
        },
        {
          name: 'Dólares',
          code: 'USD'
        }
      ]
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {}
        this.validationErrors = {}
      }
    }
  },
  methods: {
    async save () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        const obj = {
          ...this.document,
          createdAt: FieldValue.serverTimestamp(),
          uid: auth.currentUser.uid,
          state: true,
          paid: false
        }
        const doc = await db.collection('expenses').add({
          ...obj
        })
        obj.id = doc.id
        this.$emit('add', obj)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al guardar gasto')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
