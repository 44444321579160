<template>
  <Navbar :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Highlight-->
      <div class="bills__high">
        <div class="item">
          <span class="item__label">Renta mensual</span>
          <span class="item__rent">{{
              Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: client.currency.code ? `${client.currency.code}` : 'PEN'
              }).format(client.rent)
            }}</span>
        </div>
        <div class="item">
          <span class="item__label">Día de pago</span>
          <span class="item__rent">{{ client.day }}</span>
        </div>
      </div>
      <!--End-->
      <!--Total-->
      <div class="form-group">
        <label for="rent">Total</label>
        <input v-model.number="document.total" class="input" type="number" id="rent"
               name="rent">
        <span v-if="this.validationErrors.total" class="error">{{ this.validationErrors.total }}</span>
      </div>
      <!--Deadline-->
      <div class="form-group">
        <label for="deadline">Fecha de vencimiento</label>
        <v-date-picker
          id="deadline"
          name="deadline"
          v-model="document.deadline"
          color="red"
          is-expanded
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.deadline" class="error">{{ this.validationErrors.deadline }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { db, FieldValue } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

const _ = require('lodash')

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'NewTax',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    client: {
      type: Object,
      default: () => {
        return { currency: { code: '' } }
      }
    }
  },
  components: {
    Navbar,
    Shadow
  },
  data () {
    return {
      document: {},
      schema: yup.object().shape({
        deadline: yup.date().required().label('Vencimiento'),
        total: yup.number().required().positive().label('Total')
      }),
      validationErrors: {},
      progress: false
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {}
        this.document.total = _.clone(this.client.rent * 0.05)
        this.validationErrors = {}
      }
    }
  },
  methods: {
    async save () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        const obj = {
          ...this.document,
          createdAt: FieldValue.serverTimestamp(),
          uid: '',
          state: true,
          paid: false,
          client: {
            id: this.client.id,
            name: this.client.name,
            document: this.client.document,
            currency: this.client.currency,
            rent: this.client.rent,
            day: this.client.day,
            email: this.client.email,
            local: this.client.local,
            currentContract: this.client.currentContract
          }
        }
        const doc = await db.collection('clients').doc(this.client.id).collection('taxes').add({
          ...obj
        })
        obj.id = doc.id
        this.$emit('add', obj)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al guardar impuesto')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
