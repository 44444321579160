<template>
  <div>
    <div class="title">
      <div class="title__right">
        <!--Desktop back-->
        <span class="back desktop" @click="$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
        </span>
        <!--Mobile back-->
        <span @click="$router.go(-1)" class="back mobile">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
          Regresar
        </span>
        <h1>Listado de letras ({{ client.name }})</h1>
      </div>
      <div class="toolbar">
        <!---Reload-->
        <div @click="loadInfo" class="reload">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Recargar</title>
            <path
              d="M400 148l-21.12-24.57A191.43 191.43 0 00240 64C134 64 48 150 48 256s86 192 192 192a192.09 192.09 0 00181.07-128"
              fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/>
            <path
              d="M464 97.42V208a16 16 0 01-16 16H337.42c-14.26 0-21.4-17.23-11.32-27.31L436.69 86.1C446.77 76 464 83.16 464 97.42z"/>
          </svg>
        </div>
        <!--End-->
        <button @click="showGenerateSidebar= true" class="btn btn--outline">Generar letras</button>
        <button @click="showNewSidebar= true" class="btn">Agregar letra</button>
        <button @click="showNotification = true" class="btn">Enviar notificación</button>
      </div>
    </div>
    <div class="bills">
      <div class="table">
        <div class="table__header table__header--bills">
          <div><span>TOTAL</span></div>
          <div><span>FECHA DE VENCIMIENTO</span></div>
          <div><span>FECHA DE PAGO</span></div>
          <div><span>ESTADO</span></div>
          <div><span>ACCIONES</span></div>
        </div>
        <div class="table__content">
          <div v-for="(o, index) in list" :key="index" class="row row--bills"
               :class="{'selected': selected.id === o.id}" @click="selected = o">
            <div class="row__item">
              <span class="name">TOTAL</span>
              <span>{{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: `${o.client.currency.code}`
                  }).format(o.total)
                }}</span>
            </div>
            <div class="row__item">
              <span class="name">FECHA DE VENCIMIENTO</span>
              <span>{{ moment(o.deadline).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
            </div>
            <div class="row__item">
              <span class="name">FECHA DE pago</span>
              <span>{{ moment(o.paymentDate).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
            </div>
            <div class="row__item badge" :class="{'badge--error':  !o.paid}">
              <span v-if="!o.paid">Pendiente</span>
              <span v-else>Pagada</span>
            </div>
            <!--Desktop Actions-->
            <div class="row__item desktop">
              <span v-if="!o.paid" title="Editar" @click="showUpdateSidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-edit"><path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
              </span>
              <span title="Registrar pago" @click="showPaySidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path
                  d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path
                  d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              </span>
            </div>
            <!--Mobile Actions-->
            <div class="row__item mobile">
              <button @click="showPaySidebar = true, sidebarData= o" class="btn btn---block mb-8">
                Registrar pago
              </button>
              <button @click="showUpdateSidebar = true, sidebarData= o" class="btn btn---block btn--outline"
                      v-if="!o.paid">
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewBill :client="client" @close="showNewSidebar = false" @add="addSidebar" :class="{'show' : showNewSidebar}"
             title="Nueva letra" :show-navbar="showNewSidebar"></NewBill>
    <UpdateBill :data="sidebarData" @close="showUpdateSidebar = false" @update="updateSidebar"
                :class="{'show' : showUpdateSidebar}"
                title="Editar letra" :show-navbar="showUpdateSidebar"></UpdateBill>
    <generate-bills :client="client" @close="showGenerateSidebar = false" @add="addSidebar"
                    :class="{'show' : showGenerateSidebar}"
                    title="Generar letras" :show-navbar="showGenerateSidebar"></generate-bills>
    <pay :data="sidebarData" @close="showPaySidebar = false" @update="updateSidebar"
         :class="{'show' : showPaySidebar}"
         :show-navbar="showPaySidebar"></pay>

    <send-notification :show-navbar="showNotification" :data="client"
                       @close="showNotification = false"></send-notification>

    <!--Filter-->
    <div class="filter">
      <div class="filter__trigger" @click="showFilter= !showFilter">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Filtrar</title>
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M32 144h448M112 256h288M208 368h96"/>
        </svg>
      </div>
      <div id="filterContent" class="filter__content" :class="{'show': showFilter}">
        <div class="form-group">
          <label for="state">Estado de pago</label>
          <select v-model="filter.paid" class="select" name="state" id="state">
            <option :value="false">Pendiente</option>
            <option :value="true">Pagada</option>
          </select>
        </div>
        <button @click="goFilter" class="btn">Filtrar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase/firebaseConfig'
import NewBill from './NewBill'
import UpdateBill from './UpdateBill'
import GenerateBills from './Generate'
import Pay from './Pay'
import moment from 'moment'
import SendNotification from '../tenants/SendNotification.vue'

export default {
  name: 'Bills',
  components: {
    SendNotification,
    NewBill,
    UpdateBill,
    GenerateBills,
    Pay
  },
  data () {
    return {
      list: [],
      client: {
        currency: {}
      },
      moment: moment,
      showNewSidebar: false,
      showUpdateSidebar: false,
      showGenerateSidebar: false,
      showPaySidebar: false,
      showNotification: false,
      sidebarData: {},
      selected: {},
      showFilter: false,
      filter: {
        paid: false
      }
    }
  },
  watch: {
    /**
     * Show filter
     * */
    showFilter (value) {
      if (value) {
        setTimeout(() => {
          document.getElementById('filterContent').classList.add('unhide')
        }, 20)
      } else {
        setTimeout(() => {
          document.getElementById('filterContent').classList.remove('unhide')
        }, 20)
      }
    }
  },
  async created () {
    await this.loadInfo()
  },
  methods: {
    addSidebar (obj) {
      this.list.push(obj)
    },
    updateSidebar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get client
     * @returns {Promise<void>}
     */
    async getClient () {
      const doc = await db.collection('clients').doc(this.$route.params.id).get()
      if (doc.exists) {
        this.client = {
          id: doc.id,
          ...doc.data()
        }
      }
    },
    /**
     * Get bills
     * @returns {Promise<void>}
     */
    async getBills () {
      const querySnap = await db.collection('clients').doc(this.client.id).collection('bills').where('client.currentContract.id', '==', this.client.currentContract.id).orderBy('deadline', 'asc').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.deadline = doc.data().deadline.toDate()
        obj.paymentDate = doc.data().paymentDate ? doc.data().paymentDate.toDate() : null
        this.list.push(obj)
      })
    },
    /**
     * Reload
     * @returns {Promise<void>}
     */
    async loadInfo () {
      try {
        this.list = []
        this.$store.dispatch('showProgress')
        await this.getClient()
        await this.getBills()
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al cargar datos')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    },
    /**
     * Go filter
     * @returns {Promise<void>}
     */
    async goFilter () {
      try {
        this.showFilter = false
        this.$store.dispatch('showProgress')
        this.list = []
        const querySnap = await db.collection('clients').doc(this.client.id).collection('bills')
          .where('paid', '==', this.filter.paid)
          .where('client.currentContract.id', '==', this.client.currentContract.id)
          .orderBy('deadline', 'asc').get()
        querySnap.forEach((doc) => {
          const obj = {
            id: doc.id,
            ...doc.data()
          }
          obj.deadline = doc.data().deadline.toDate()
          obj.paymentDate = doc.data().paymentDate ? doc.data().paymentDate.toDate() : null
          this.list.push(obj)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al filtrar')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    }
  }
}
</script>

<style scoped>

</style>
