<template>
  <div>
    <div class="title">
      <div class="title__right">
        <!--Desktop back-->
        <span class="back desktop" @click="$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
        </span>
        <!--Mobile back-->
        <span @click="$router.go(-1)" class="back mobile">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
          Regresar
        </span>
        <h1>Listado de gastos</h1>
      </div>
      <div class="toolbar">
        <!---Reload-->
        <div @click="loadInfo" class="reload">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Recargar</title>
            <path
              d="M400 148l-21.12-24.57A191.43 191.43 0 00240 64C134 64 48 150 48 256s86 192 192 192a192.09 192.09 0 00181.07-128"
              fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/>
            <path
              d="M464 97.42V208a16 16 0 01-16 16H337.42c-14.26 0-21.4-17.23-11.32-27.31L436.69 86.1C446.77 76 464 83.16 464 97.42z"/>
          </svg>
        </div>
        <!--End-->
        <!--Local-->
        <div class="form-group form-group--m0">
          <select v-model="local" class="select select--bwhite" name="local" id="local">
            <option value="All">Todas las propiedades</option>
            <option v-for="(e, index) in estates" :value="e" :key="index">{{ e.description }}</option>
          </select>
        </div>
        <!--End-->
        <button @click="showNewSidebar= true" class="btn">Agregar gasto</button>
      </div>
    </div>
    <div class="bills">
      <div class="table">
        <div class="table__header table__header--six">
          <div><span>LOCAL</span></div>
          <div><span>TIPO</span></div>
          <div><span>TOTAL</span></div>
          <div><span>FECHA DE VENCIMIENTO</span></div>
          <div><span>ESTADO</span></div>
          <div><span>ACCIONES</span></div>
        </div>
        <div class="table__content">
          <div v-for="(o, index) in list" :key="index" class="row row--six"
               :class="{'selected': selected.id === o.id}" @click="selected = o">
            <div class="row__item">
              <span class="name">LOCAL</span>
              <span>{{ o.local.description }}</span>
            </div>
            <div class="row__item">
              <span class="name">TIPO</span>
              <span>{{ o.type }}</span>
            </div>
            <div class="row__item">
              <span class="name">TOTAL</span>
              <span>{{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: `${o.currency.code}`
                  }).format(o.total)
                }}</span>
            </div>
            <div class="row__item">
              <span class="name">FECHA DE VENCIMIENTO</span>
              <span>{{ moment(o.deadline).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
            </div>
            <div class="row__item badge" :class="{'badge--error':  !o.paid}">
              <span v-if="!o.paid">Pendiente</span>
              <span v-else>Pagada</span>
            </div>
            <!--Desktop Actions-->
            <div class="row__item desktop">
              <span v-if="!o.paid" title="Editar" @click="showUpdateSidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-edit"><path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
              </span>
              <span title="Registrar pago" @click="showPaySidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path
                  d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path
                  d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              </span>
            </div>
            <!--Mobile Actions-->
            <div class="row__item mobile">
              <button @click="showPaySidebar = true, sidebarData= o" class="btn btn---block mb-8">
                Registrar pago
              </button>
              <button @click="showUpdateSidebar = true, sidebarData= o" class="btn btn---block btn--outline"
                      v-if="!o.paid">
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewExpense :estates="estates" @close="showNewSidebar = false" @add="addSidebar" :class="{'show' : showNewSidebar}"
                title="Nuevo gasto" :show-navbar="showNewSidebar"></NewExpense>
    <UpdateExpense :estates="estates" :data="sidebarData" @close="showUpdateSidebar = false" @update="updateSidebar"
                   :class="{'show' : showUpdateSidebar}"
                   title="Editar gasto" :show-navbar="showUpdateSidebar"></UpdateExpense>
    <pay :data="sidebarData" @close="showPaySidebar = false" @update="updateSidebar"
         :class="{'show' : showPaySidebar}"
         :show-navbar="showPaySidebar"></pay>
    <!--Filter-->
    <div class="filter">
      <div class="filter__trigger" @click="showFilter= !showFilter">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Filtrar</title>
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M32 144h448M112 256h288M208 368h96"/>
        </svg>
      </div>
      <div id="filterContent" class="filter__content" :class="{'show': showFilter}">
        <!--Deadline-->
        <div class="form-group">
          <label for="deadline">Fecha de vencimiento</label>
          <v-date-picker
            id="deadline"
            name="deadline"
            v-model="filter.deadline"
            :dot="true"
            color="red"
            is-expanded
            is-range
            :timezone="`America/Lima`"
          />
        </div>
        <!--Payment state-->
        <div class="form-group">
          <label for="paymentState">Estado de pago</label>
          <select v-model="filter.paid" class="select" name="state" id="paymentState">
            <option :value="false">Sin pagar</option>
            <option :value="true">Pagado</option>
          </select>
        </div>
        <button @click="goFilter" class="btn">Filtrar</button>
      </div>
    </div>
    <!--End-->
  </div>
</template>

<script>
import { db, Timestamp } from '@/firebase/firebaseConfig'
import NewExpense from './NewExpense'
import UpdateExpense from './UpdateExpense'
import Pay from './Pay'
import moment from 'moment'

export default {
  name: 'Expenses',
  components: {
    NewExpense,
    UpdateExpense,
    Pay
  },
  data () {
    return {
      list: [],
      estates: [],
      moment: moment,
      showNewSidebar: false,
      showUpdateSidebar: false,
      showPaySidebar: false,
      sidebarData: {},
      selected: {},
      local: 'All',
      showFilter: false,
      filter: {
        deadline: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
        },
        paid: false
      }
    }
  },
  watch: {
    /**
     * Filter by local
     * */
    async local (value) {
      try {
        if (value !== 'All') {
          this.$store.dispatch('showProgress')
          this.list = []
          const querySnap = await db.collection('expenses').orderBy('deadline', 'asc').where('local.id', '==', value.id).get()
          querySnap.forEach((doc) => {
            const obj = {
              id: doc.id,
              ...doc.data()
            }
            obj.deadline = doc.data().deadline ? doc.data().deadline.toDate() : null
            obj.paymentDate = doc.data().paymentDate ? doc.data().paymentDate.toDate() : null
            this.list.push(obj)
          })
        } else {
          this.$store.dispatch('showProgress')
          await this.getExpenses()
        }
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al cargar inquilinos')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    },
    /**
     * Show filter
     * */
    showFilter (value) {
      if (value) {
        setTimeout(() => {
          document.getElementById('filterContent').classList.add('unhide')
        }, 20)
      } else {
        setTimeout(() => {
          document.getElementById('filterContent').classList.remove('unhide')
        }, 20)
      }
    }
  },
  async created () {
    await this.loadInfo()
  },
  methods: {
    addSidebar (obj) {
      this.list.push(obj)
    },
    updateSidebar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get clients of firestore
     * @returns {Promise<void>}
     */
    async getEstates () {
      this.estates = []
      const querySnap = await db.collection('estates').orderBy('createdAt').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        this.estates.push(obj)
      })
    },
    /**
     * Get expenses
     * @returns {Promise<void>}
     */
    async getExpenses () {
      this.list = []
      const querySnap = await db.collection('expenses').orderBy('deadline', 'desc').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.deadline = doc.data().deadline ? doc.data().deadline.toDate() : null
        obj.paymentDate = doc.data().paymentDate ? doc.data().paymentDate.toDate() : null
        this.list.push(obj)
      })
    },
    /**
     * Go filter
     * @returns {Promise<void>}
     */
    async goFilter () {
      try {
        this.showFilter = false
        this.$store.dispatch('showProgress')
        this.list = []
        const start = Timestamp.fromDate(this.filter.deadline.start)
        const end = Timestamp.fromDate(this.filter.deadline.end)
        const querySnap = await db.collection('expenses')
          .where('paid', '==', this.filter.paid)
          .where('deadline', '>=', start)
          .where('deadline', '<=', end)
          .orderBy('deadline', 'asc').get()
        querySnap.forEach((doc) => {
          const obj = {
            id: doc.id,
            ...doc.data()
          }
          obj.deadline = doc.data().deadline ? doc.data().deadline.toDate() : null
          obj.paymentDate = doc.data().paymentDate ? doc.data().paymentDate.toDate() : null
          this.list.push(obj)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al filtrar')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    },
    /**
     * Reload
     * @returns {Promise<void>}
     */
    async loadInfo () {
      try {
        this.$store.dispatch('showProgress')
        await this.getEstates()
        await this.getExpenses()
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al cargar datos')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    }
  }
}
</script>

<style scoped>

</style>
