<template>
  <Navbar :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Highlight-->
      <div class="bills__high">
        <div class="item">
          <span class="item__label">Renta mensual</span>
          <span class="item__rent">{{
              Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: document.client.currency.code ? `${document.client.currency.code}` : 'PEN'
              }).format(document.client.rent)
            }}</span>
        </div>
        <div class="item">
          <span class="item__label">Día de pago</span>
          <span class="item__rent">{{ document.client.day }}</span>
        </div>
      </div>
      <!--End-->
      <!--Total-->
      <div class="form-group">
        <label for="rent">Total</label>
        <input v-model.number="document.total" class="input" type="number" id="rent"
               name="rent">
        <span v-if="this.validationErrors.total" class="error">{{ this.validationErrors.total }}</span>
      </div>
      <!--Deadline-->
      <div class="form-group">
        <label for="deadline">Fecha de vencimiento</label>
        <v-date-picker
          ref="deadline"
          id="deadline"
          name="deadline"
          v-model="document.deadline"
          color="red"
          is-expanded
          :timezone="`America/Lima`"
        />
        <span v-if="this.validationErrors.deadline" class="error">{{ this.validationErrors.deadline }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { db, FieldValue } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

const _ = require('lodash')

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'UpdateBill',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Navbar,
    Shadow
  },
  data () {
    return {
      document: {
        client: {
          currency: {
            code: 'PEN'
          }
        }
      },
      schema: yup.object().shape({
        deadline: yup.date().required().label('Vencimiento'),
        total: yup.number().required().positive().label('Total')
      }),
      validationErrors: {},
      progress: false
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = _.clone(this.data)
        this.validationErrors = {}
        // Move calendar
        const deadline = this.$refs.deadline
        deadline.move(this.document.deadline)
      }
    }
  },
  methods: {
    async save () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        const obj = {
          ...this.document,
          updatedAt: FieldValue.serverTimestamp(),
          uid: ''
        }
        await db.collection('clients').doc(this.document.client.id).collection('bills').doc(this.document.id).update({
          ...obj
        })
        this.$emit('update', obj)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al editar letra')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
