<template>
  <Navbar :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Name-->
      <div class="form-group">
        <label for="description">Descripción</label>
        <input v-model.trim="document.description" class="input" type="text" id="description">
        <span v-if="this.validationErrors.description" class="error">{{ this.validationErrors.description }}</span>
      </div>
      <!--M2-->
      <div class="form-group">
        <label for="m2">M2</label>
        <input v-model.number="document.m2" class="input" type="number" id="m2">
        <span v-if="this.validationErrors.m2" class="error">{{ this.validationErrors.m2 }}</span>
      </div>
      <!--Address-->
      <div class="form-group">
        <label for="address">Dirección</label>
        <input v-model.trim="document.address" class="input" type="text" id="address">
        <span v-if="this.validationErrors.address" class="error">{{ this.validationErrors.address }}</span>
      </div>
      <!--Owner-->
      <div class="form-group">
        <label for="description">Propietario</label>
        <input v-model.trim="document.owner" class="input" type="text" id="owner" name="owner">
        <span v-if="this.validationErrors.owner" class="error">{{ this.validationErrors.owner }}</span>
      </div>
      <!--Province-->
      <div class="form-group">
        <label for="description">Provincia</label>
        <input @blur="document.province =  document.province.toUpperCase()" v-model.trim="document.province"
               class="input" type="text" id="province" name="province">
        <span v-if="this.validationErrors.province" class="error">{{ this.validationErrors.province }}</span>
      </div>
      <!--District-->
      <div class="form-group">
        <label for="description">Distrito</label>
        <input @blur="document.district =  document.district.toUpperCase()" v-model.trim="document.district"
               class="input" type="text" id="district" name="district">
        <span v-if="this.validationErrors.district" class="error">{{ this.validationErrors.district }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { db, FieldValue } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

const _ = require('lodash')

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero'
  }
})

export default {
  name: 'UpdateEstate',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Navbar,
    Shadow
  },
  data () {
    return {
      document: {},
      schema: yup.object().shape({
        description: yup.string().required().label('Descripción'),
        m2: yup.number().required().positive().integer().label('M2'),
        address: yup.string().required().label('Dirección'),
        owner: yup.string().required().label('Propietario'),
        province: yup.string().required().label('Provincia'),
        district: yup.string().required().label('Distrito')
      }),
      validationErrors: {},
      progress: false
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = _.cloneDeep(this.data)
        this.validationErrors = {}
      }
    }
  },
  methods: {
    async save () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        const obj = {
          ...this.document,
          updatedAt: FieldValue.serverTimestamp(),
          uid: ''
        }
        await db.collection('estates').doc(this.document.id).update({
          ...obj
        })
        this.$emit('update', obj)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al editar propiedad')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
