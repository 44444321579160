<template>
  <Navbar title="Registrar pago" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <template v-if="!data.paid">
        <!--Highlight-->
        <div v-if="data.client" class="bills__high bills__high--green">
          <div class="item">
            <span class="item__label">Monto a pagar</span>
            <span class="item__rent">{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: data.client.currency ? data.client.currency.code : 'PEN'
                }).format(data.total)
              }}</span>
          </div>
        </div>
        <!--End-->
        <!--Payment date-->
        <div class="form-group">
          <label for="paymentDate">Fecha de pago</label>
          <v-date-picker
            id="deadline"
            name="deadline"
            v-model="document.paymentDate"
            color="red"
            is-expanded
            :timezone="`America/Lima`"
          />
          <span v-if="this.validationErrors.paymentDate" class="error">{{ this.validationErrors.paymentDate }}</span>
        </div>
        <!--Voucher-->
        <div class="form-group">
          <input @change="updateImage" class="file" ref="uploadVoucher" type="file" accept="image/*">
          <button class="btn btn--outline btn--w100" @click="$refs.uploadVoucher.click()">Adjuntar comprobante</button>
          <img class="preview" v-if="attach" :src="preview" alt="Voucher">
        </div>
      </template>
      <template v-else>
        <!--Highlight-->
        <div v-if="document.client" class="bills__high bills__high--green">
          <div class="item">
            <span class="item__label">Total pagado</span>
            <span class="item__rent">{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: document.client.currency ? document.client.currency.code : 'PEN'
                }).format(document.total)
              }}</span>
          </div>
          <div class="item">
            <span class="item__label">Fecha de pago</span>
            <span class="item__rent">{{ moment(document.paymentDate).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
          </div>
        </div>
        <!--End-->
        <!--Voucher-->
        <div v-if="document.voucher" class="media">
          <img :src="document.voucher" alt="Voucher de pago">
          <div @click="openImg(document.voucher)" class="media__open">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-external-link">
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
              <polyline points="15 3 21 3 21 9"></polyline>
              <line x1="10" y1="14" x2="21" y2="3"></line>
            </svg>
          </div>
        </div>
      </template>
    </template>
    <template v-slot:footer>
      <button :disabled="progress|| data.paid" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Registrar pago</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import moment from 'moment'

import * as yup from 'yup'

import { FieldValue, db, storage } from '@/firebase/firebaseConfig'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'PayTax',
  components: {
    Navbar,
    Shadow
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    data: {
      type: Object,
      default: () => {
        return { currency: { code: '' } }
      }
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {}
        this.validationErrors = {}
        this.preview = ''
        this.attach = null
        if (this.data.paid) {
          this.document = {
            ...this.data
          }
        }
      }
    }
  },
  data () {
    return {
      document: {},
      progress: false,
      schema: yup.object().shape({
        paymentDate: yup.date().required().label('Fecha de pago')
      }),
      include: true,
      validationErrors: {},
      attach: null,
      preview: '',
      moment: moment
    }
  },
  methods: {
    /**
     * Save data in firestore
     * */
    async save () {
      try {
        this.progress = true
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        this.validationErrors = {}
        const obj = {
          paymentDate: this.document.paymentDate,
          paid: true,
          updatedAt: FieldValue.serverTimestamp(),
          voucher: ''
        }
        if (this.attach) {
          obj.voucher = await this.submitFile(this.attach)
        }
        await db.collection('clients').doc(this.data.client.id).collection('taxes').doc(this.data.id).update({
          ...obj
        })
        obj.id = this.data.id
        this.$emit('update', obj)
        this.$emit('close')
      } catch (err) {
        console.log(err)
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al registrar pago')
        }
      } finally {
        this.progress = false
      }
    },
    updateImage (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.preview = e.target.result
          this.attach = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Save doc in firebase storage
     * @param doc
     * @returns {Promise<unknown>}
     */
    submitFile (doc) {
      return new Promise((resolve, reject) => {
        const docName = Math.random().toString(36).substr(1, 20)
        const fileExtension = '.' + doc.name.split('.').pop()
        const docNameFinal = docName + fileExtension

        const storageRef = storage.ref(`vouchers/${docNameFinal}`)
        storageRef.put(doc)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              resolve(url)
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    openImg (img) {
      window.open(img, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
