<template>
  <div>
    <div class="title">
      <div class="title__right">
        <!--Desktop back-->
        <span class="back desktop" @click="$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
        </span>
        <!--Mobile back-->
        <span @click="$router.go(-1)" class="back mobile">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
            d="M328 112L184 256l144 144"/></svg>
          Regresar
        </span>
        <h1>Listado de ventas</h1>
      </div>
      <div class="toolbar">
        <!---Reload-->
        <div @click="loadInfo" class="reload">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Recargar</title>
            <path
              d="M400 148l-21.12-24.57A191.43 191.43 0 00240 64C134 64 48 150 48 256s86 192 192 192a192.09 192.09 0 00181.07-128"
              fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/>
            <path
              d="M464 97.42V208a16 16 0 01-16 16H337.42c-14.26 0-21.4-17.23-11.32-27.31L436.69 86.1C446.77 76 464 83.16 464 97.42z"/>
          </svg>
        </div>
        <!--End-->
        <button @click="showNewSidebar= true" class="btn">Agregar venta</button>
      </div>
    </div>
    <div class="bills">
      <div class="table">
        <div class="table__header table__header--four">
          <div><span>EXPOSITOR</span></div>
          <div><span>TOTAL</span></div>
          <div><span>FECHA DE EMISIÓN</span></div>
          <div><span>ACCIONES</span></div>
        </div>
        <div class="table__content">
          <div v-for="(o, index) in list" :key="index" class="row row--four"
               :class="{'selected': selected.id === o.id}" @click="selected = o">
            <div class="row__item">
              <span class="name">EXPOSITOR</span>
              <span>{{ o.client.alias }}</span>
            </div>
            <div class="row__item">
              <span class="name">TOTAL</span>
              <span>{{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: `${o.currency.code}`
                  }).format(o.total)
                }}</span>
            </div>
            <div class="row__item">
              <span class="name">FECHA DE EMISIÓN</span>
              <span>{{ moment(o.dateOfIssue).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
            </div>
            <!--Desktop Actions-->
            <div class="row__item desktop">
              <span title="Editar" @click="showUpdateSidebar = true, sidebarData= o">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-edit"><path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
              </span>
            </div>
            <!--Mobile Actions-->
            <div class="row__item mobile">
              <button @click="showUpdateSidebar = true, sidebarData= o" class="btn btn---block btn--outline">
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewSale :clients="clients" @close="showNewSidebar = false" @add="addSidebar" :class="{'show' : showNewSidebar}"
             title="Nueva venta" :show-navbar="showNewSidebar"></NewSale>
    <UpdateSale :clients="clients" :data="sidebarData" @close="showUpdateSidebar = false" @update="updateSidebar"
                :class="{'show' : showUpdateSidebar}"
                title="Editar venta" :show-navbar="showUpdateSidebar"></UpdateSale>
    <!--Filter-->
    <div class="filter">
      <div class="filter__trigger" @click="showFilter= !showFilter">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Filtrar</title>
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M32 144h448M112 256h288M208 368h96"/>
        </svg>
      </div>
      <div id="filterContent" class="filter__content" :class="{'show': showFilter}">
        <!--Date Of Issue-->
        <div class="form-group">
          <label for="">Fecha de emisión</label>
          <v-date-picker
            id="dateOfIssue"
            name="dateOfIssue"
            v-model="filter.dateOfIssue"
            :dot="true"
            color="red"
            is-expanded
            is-range
            :timezone="`America/Lima`"
          />
        </div>
        <button @click="goFilter" class="btn">Filtrar</button>
      </div>
    </div>
    <!--End-->
  </div>
</template>

<script>
import { db, Timestamp } from '@/firebase/firebaseConfig'
import NewSale from './NewSale'
import UpdateSale from './UpdateSale'
import moment from 'moment'

export default {
  name: 'Sales',
  components: {
    NewSale,
    UpdateSale
  },
  data () {
    return {
      list: [],
      clients: [],
      moment: moment,
      showNewSidebar: false,
      showUpdateSidebar: false,
      showPaySidebar: false,
      sidebarData: {},
      selected: {},
      local: 'All',
      showFilter: false,
      filter: {
        dateOfIssue: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
        },
        paid: false
      }
    }
  },
  watch: {
    /**
     * Show filter
     * */
    showFilter (value) {
      if (value) {
        setTimeout(() => {
          document.getElementById('filterContent').classList.add('unhide')
        }, 20)
      } else {
        setTimeout(() => {
          document.getElementById('filterContent').classList.remove('unhide')
        }, 20)
      }
    }
  },
  async created () {
    await this.loadInfo()
  },
  methods: {
    addSidebar (obj) {
      this.list.push(obj)
    },
    updateSidebar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get clients of firestore
     * @returns {Promise<void>}
     */
    async getClients () {
      this.clients = []
      const querySnap = await db.collection('clients').where('local.id', '==', 'RCRe9vImYXGy0eEBuNV5').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        this.clients.push(obj)
      })
    },
    /**
     * Get sales of firestore
     * @returns {Promise<void>}
     */
    async getSales () {
      this.list = []
      const querySnap = await db.collection('sales').limit(50).orderBy('dateOfIssue', 'desc').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data(),
          dateOfIssue: doc.data().dateOfIssue.toDate()
        }
        this.list.push(obj)
      })
    },
    /**
     * Go filter
     * @returns {Promise<void>}
     */
    async goFilter () {
      try {
        this.showFilter = false
        this.$store.dispatch('showProgress')

        const start = this.filter.dateOfIssue.start
        const end = this.filter.dateOfIssue.end
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(0)
        console.log(start)
        console.log(end)

        this.list = []
        const startTime = Timestamp.fromDate(start)
        const endTime = Timestamp.fromDate(end)
        const querySnap = await db.collection('sales')
          .where('dateOfIssue', '>=', startTime)
          .where('dateOfIssue', '<=', endTime)
          .orderBy('dateOfIssue', 'asc').get()
        querySnap.forEach((doc) => {
          const obj = {
            id: doc.id,
            ...doc.data()
          }
          obj.dateOfIssue = doc.data().dateOfIssue ? doc.data().dateOfIssue.toDate() : null
          this.list.push(obj)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al filtrar')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    },
    /**
     * Reload
     * @returns {Promise<void>}
     */
    async loadInfo () {
      try {
        this.$store.dispatch('showProgress')
        await this.getSales()
        await this.getClients()
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al cargar datos')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    }
  }
}
</script>

<style scoped>

</style>
