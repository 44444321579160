<template>
  <Navbar :title="title" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Local-->
      <div class="form-group">
        <label for="local">Propiedad</label>
        <select v-model="document.local" class="select" name="local" id="local">
          <option v-for="(e, index) in estates" :value="e" :key="index">{{ e.description }}</option>
        </select>
        <span v-if="this.validationErrors.local" class="error">{{ this.validationErrors.local }}</span>
      </div>
      <!--Name-->
      <div class="form-group">
        <label for="name">Nombre (Razón social y/o nombre)</label>
        <input v-model="document.name" class="input" type="text" id="name">
        <span v-if="this.validationErrors.name" class="error">{{ this.validationErrors.name }}</span>
      </div>
      <!--Alias-->
      <div class="form-group">
        <label for="alias">Alias</label>
        <input v-model="document.alias" class="input" type="text" id="alias">
        <span v-if="this.validationErrors.alias" class="error">{{ this.validationErrors.alias }}</span>
      </div>
      <!--Type document-->
      <div class="form-group">
        <label for="typeDocument">Tipo de documento</label>
        <select v-model="document.typeDocument" class="select" name="typeDocument" id="typeDocument">
          <option value="RUC">RUC</option>
          <option value="DNI">DNI</option>
        </select>
        <span v-if="this.validationErrors.typeDocument" class="error">{{ this.validationErrors.typeDocument }}</span>
      </div>
      <!--Document-->
      <div class="form-group">
        <label for="document">Documento</label>
        <input v-model.trim="document.document" class="input" type="text" id="document">
        <span v-if="this.validationErrors.document" class="error">{{ this.validationErrors.document }}</span>
      </div>
      <!--Email-->
      <div class="form-group">
        <label for="email">Email</label>
        <input v-model.trim="document.email" class="input" type="text" id="email">
        <span v-if="this.validationErrors.email" class="error">{{ this.validationErrors.email }}</span>
      </div>
      <!--Phone-->
      <div class="form-group">
        <label for="email">Celular</label>
        <input v-model.trim="document.phone" class="input" type="text" id="phone">
        <span v-if="this.validationErrors.phone" class="error">{{ this.validationErrors.phone }}</span>
      </div>
      <!--Day-->
      <div class="form-group">
        <label for="day">Día de pago</label>
        <input v-model.number="document.day" class="input" type="number" id="day">
        <span v-if="this.validationErrors.day" class="error">{{ this.validationErrors.day }}</span>
      </div>
      <!--M2-->
      <div class="form-group">
        <label for="m2">Metros cuadrados</label>
        <input v-model.number="document.m2" class="input" type="number" id="m2" name="=m2">
        <span v-if="this.validationErrors.m2" class="error">{{ this.validationErrors.m2 }}</span>
      </div>
      <!--Payment method-->
      <div class="form-group">
        <label for="local">Método de pago</label>
        <select v-model="document.paymentMethod" class="select" name="paymentMethod" id="paymentMethod">
          <option v-for="(o, index) in paymentMethods" :value="o" :key="index">{{ o }}</option>
        </select>
        <span v-if="this.validationErrors.paymentMethod" class="error">{{ this.validationErrors.paymentMethod }}</span>
      </div>
      <!--Observation-->
      <div class="form-group">
        <label for="name">Observación</label>
        <input v-model="document.observation" class="input" type="text" id="observation" name="observation">
        <span v-if="this.validationErrors.observation" class="error">{{ this.validationErrors.name }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button :disabled="progress" :class="{'is-loading': progress}" @click="save" class="btn">
        <span v-if="!progress">Guardar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Shadow from '@/components/Shadow'
import { db, FieldValue } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero'
  },
  string: {
    email: 'Debe ser  un email'
  }
})

export default {
  name: 'NewTenant',
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    title: {
      type: String
    },
    estates: {
      type: Array,
      required: true
    }
  },
  components: {
    Navbar,
    Shadow
  },
  data () {
    return {
      document: {},
      schema: yup.object().shape({
        local: yup.object().required().label('Propiedad'),
        name: yup.string().required().label('Nombre'),
        alias: yup.string().required().label('Alias'),
        typeDocument: yup.string().required().label('Tipo de documento'),
        document: yup.string().required().label('Documento'),
        email: yup.string().email().required().label('Email'),
        day: yup.number().required().positive().integer().label('Día'),
        m2: yup.number().required().positive().integer().label('M2'),
        observation: yup.string().required().label('Observación'),
        paymentMethod: yup.string().required().label('Método de pago')
      }),
      validationErrors: {},
      currencies: [
        {
          name: 'Soles',
          code: 'PEN'
        },
        {
          name: 'Dólares',
          code: 'USD'
        }
      ],
      progress: false,
      paymentMethods: [
        'Efectivo',
        'Depósito',
        'Cheque',
        'Transferencia'
      ]
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {}
        this.validationErrors = {}
      }
    }
  },
  methods: {
    async save () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        const obj = {
          ...this.document,
          createdAt: FieldValue.serverTimestamp(),
          uid: '',
          state: true,
          good: true
        }
        const doc = await db.collection('clients').add({
          ...obj
        })
        obj.id = doc.id
        this.$emit('add', obj)
        this.$emit('close')
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al guardar inquilino')
        }
        console.log(err)
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
